<template>
  <div>
    <div>
      <o-period-selector
        ref="periodSelector"
        :mode="mode"
        :date="current"
        @mode-change="onModeChange"
        @date-change="onDateChange"
      >
        <span slot="actions">
          <v-btn-toggle
            v-model="dataType"
            color="secondary"
            dense
            group
          >
            <v-btn
              value="speed"
              text
            >
              <v-icon
                size="20"
                class="mr-1"
              >
                mdi-speedometer
              </v-icon>
              {{ $t('messages.views.admin.components.dashboard.sectionModal.window.history.speed') }}
            </v-btn>
            <v-btn
              value="jamFactor"
              text
            >
              <v-icon
                size="20"
                class="mr-1"
              >
                mdi-car-multiple
              </v-icon>
              {{ $t('messages.views.admin.components.dashboard.sectionModal.window.history.jam') }}
            </v-btn>
          </v-btn-toggle>
        </span>
      </o-period-selector>
    </div>

    <div v-if="dataType === 'speed'">
      <v-row>
        <v-col>
          <o-card-value
            class="elevation-0 rounded-lg"
            outlined
            :title="$t('labels.section.averageSpeed')"
            :loading="loading"
            :value-color="speedColor"
            :value="`${getChartDataAverageValue(history.averageSpeed)} km/h`"
            :has-data="hasData(history.averageSpeed)"
          />
        </v-col>
        <v-col>
          <o-card-value
            class="elevation-0 rounded-lg"
            outlined
            :title="$t('labels.section.averageSpeedUncut')"
            :loading="loading"
            :value-color="speedColor"
            :value="`${getChartDataAverageValue(history.averageSpeedUncut)} km/h`"
            :has-data="hasData(history.averageSpeedUncut)"
          />
        </v-col>
        <v-col>
          <o-card-value
            class="elevation-0 rounded-lg"
            outlined
            :title="$t('labels.section.freeFlowSpeed')"
            :loading="loading"
            :value-color="speedColor"
            :value="`${getChartDataAverageValue(history.freeFlowSpeed)} km/h`"
            :has-data="hasData(history.freeFlowSpeed)"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="6"
        >
          <o-card-stat
            class="elevation-0 rounded-lg fill-height history-card"
            outlined
            :title="$t('messages.views.admin.components.dashboard.sectionModal.window.history.speedEvolution')"
            :loading="loading"
            :has-data="hasData(history.averageSpeed)"
            @download-chart-as-csv="() => downloadChartAsCsv('speedEvolution')"
            @download-chart-as-png="() => downloadChartAsPng('speedEvolution')"
          >
            <template
              v-slot:default="{ expanded }"
              class="fill-height fill-width"
            >
              <o-chart-speed-evolution
                ref="speedEvolutionChart"
                class="fill-height fill-width"
                :labels="chartLabels"
                :data="history.averageSpeed"
                :free-flow-speed="getChartDataAverageValue(history.freeFlowSpeed)"
                :height="expanded ? 1000 : chartHeight"
              />
            </template>
          </o-card-stat>
        </v-col>

        <v-col
          cols="6"
        >
          <o-card-stat
            class="elevation-0 rounded-lg fill-height history-card"
            outlined
            :title="$t('messages.views.admin.components.dashboard.sectionModal.window.history.speedDistribution')"
            :loading="loading"
            :has-data="hasData(history.averageSpeed)"
            @download-chart-as-csv="() => downloadChartAsCsv('speedDistribution')"
            @download-chart-as-png="() => downloadChartAsPng('speedDistribution')"
          >
            <template
              v-slot:default="{ expanded }"
              class="fill-height fill-width"
            >
              <o-chart-speed-distribution
                ref="speedDistributionChart"
                class="fill-height fill-width"
                :data="history.averageSpeed"
                :height="expanded ? 1000 : chartHeight"
                :slices="speedDistributionSlices"
              />
            </template>
          </o-card-stat>
        </v-col>
      </v-row>
    </div>

    <div v-if="dataType === 'jamFactor'">
      <v-row>
        <v-col>
          <o-card-value
            class="elevation-0 rounded-lg d-flex flex-column"
            outlined
            :title="$t('messages.views.admin.components.dashboard.sectionModal.window.history.jamType')"
            :loading="loading"
            :value-color="jamColor"
            :value="$t('constants.section.jamFactor.' + averageJamType)"
            :has-data="hasData(history.jamFactor)"
          />
        </v-col>
        <v-col>
          <o-card-value
            class="elevation-0 rounded-lg d-flex flex-column"
            outlined
            :title="$t('messages.views.admin.components.dashboard.sectionModal.window.history.jamTime')"
            :loading="loading"
            :value-color="jamColor"
            :value="jamTime"
            :has-data="hasData(history.jamFactor)"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="6"
        >
          <o-card-stat
            class="elevation-0 rounded-lg fill-height history-card"
            outlined
            :title="$t('messages.views.admin.components.dashboard.sectionModal.window.history.jamFactorEvolution')"
            :loading="loading"
            :has-data="hasData(history.jamFactor)"
            @download-chart-as-csv="() => downloadChartAsCsv('jamFactorEvolution')"
            @download-chart-as-png="() => downloadChartAsPng('jamFactorEvolution')"
          >
            <template
              v-slot:default="{ expanded }"
              class="fill-height fill-width"
            >
              <o-chart-jam-factor-evolution
                ref="jamFactorEvolutionChart"
                class="fill-height fill-width"
                :labels="chartLabels"
                :data="history.jamFactor"
                :height="expanded ? 1000 : chartHeight"
              />
            </template>
          </o-card-stat>
        </v-col>

        <v-col
          cols="6"
        >
          <o-card-stat
            class="elevation-0 rounded-lg fill-height history-card"
            outlined
            :title="$t('messages.views.admin.components.dashboard.sectionModal.window.history.jamFactorDistribution')"
            :loading="loading"
            :has-data="hasData(history.jamFactor)"
            @download-chart-as-csv="() => downloadChartAsCsv('jamFactorDistribution')"
            @download-chart-as-png="() => downloadChartAsPng('jamFactorDistribution')"
          >
            <template
              v-slot:default="{ expanded }"
              class="fill-height fill-width"
            >
              <o-chart-jam-factor-distribution
                ref="jamFactorDistributionChart"
                class="fill-height fill-width"
                :data="history.jamFactor"
                :labels="[]"
                :height="expanded ? 1000 : chartHeight"
              />
            </template>
          </o-card-stat>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  import { getJamFactorTextColorFromValue, getJamFactorType, getSpeedColor } from '@utils/section';
  import { downloadCanvasAsPng, downloadDataAsCsv } from '@/utils/download';
  const moment = require('moment-timezone');

  export default {
    props: {
      value: {
        type: [Number, String],
        required: true,
      },
      date: {
        type: Date,
        default: null,
      },
    },

    apollo: {
      data: {
        query: require('@gql/views/admin/components/dashboard/section-modal/window/history/history.gql'),
        variables: function () {
          return {
            timezone: moment.tz.guess(),
            from: this.from.clone().utc().format('Y-MM-DD HH:mm:ss'),
            to: this.to.clone().utc().format('Y-MM-DD HH:mm:ss'),
            step: this.step,
            entityId: this.value,
          };
        },
        client: 'stats',
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        result (res, key) {
          this.loading = res.loading;
        },
        update: function (data) {
          this.history.jamFactor = this.historyToChartData(this.timestamps, data.jamFactor);
          this.history.freeFlowSpeed = this.historyToChartData(this.timestamps, data.freeFlowSpeed);
          this.history.averageSpeed = this.historyToChartData(this.timestamps, data.averageSpeed);
          this.history.averageSpeedUncut = this.historyToChartData(this.timestamps, data.averageSpeedUncut);
        },
      },
    },

    data: () => ({
      dataType: 'speed',
      speedDistributionSlices: 2,
      chartHeight: 280,
      current: moment(new Date()).set({
        minute: 0,
        second: 0,
        millisecond: 0,
      }).toDate(),
      mode: 'day',
      loading: true,
      history: {
        jamFactor: null,
        freeFlowSpeed: null,
        averageSpeed: null,
        averageSpeedUncut: null,
      },
    }),

    computed: {
      from () {
        switch (this.mode) {
          case 'hour':
            return moment(this.current);
          case 'day':
            return moment(this.current).set({
              hour: 0,
            });
          case 'week':
            return moment(this.current).set({
              isoWeekday: 1,
              hour: 0,
            });
          case 'month':
            return moment(this.current).set({
              date: 1,
              hour: 0,
            });
          default:
            throw new Error('Invalid mode');
        }
      },
      to () {
        const to = this.from.clone();
        switch (this.mode) {
          case 'hour':
            return to.add(1, 'hours');
          case 'day':
            return to.add(1, 'days');
          case 'week':
            return to.add(7, 'days');
          case 'month':
            return to.add(1, 'months').date(0);
          default:
            throw new Error('Invalid mode');
        }
      },
      step () {
        switch (this.mode) {
          case 'hour':
            return 10;
          case 'day':
            return 30;
          case 'week':
            return 60 * 24;
          case 'month':
            return 60 * 24;
          default:
            throw new Error('Invalid mode');
        }
      },
      timestamps () {
        const timestamps = [];
        const startingTimeStamp = parseInt(this.from.format('X'));
        const endingTimeStamp = parseInt(this.to.format('X'));
        let currentTimestamp = startingTimeStamp;

        while (currentTimestamp < endingTimeStamp) {
          timestamps.push(currentTimestamp);
          currentTimestamp += this.step * 60;
        }

        return timestamps;
      },
      chartLabels () {
        return this.timestamps.map((timestamp) => {
          const date = moment.unix(timestamp);
          switch (this.mode) {
            case 'hour':
            case 'day':
              return date.format('LT');
            case 'week':
              return [
                date.format('dddd'),
                date.format('L'),
              ];
            default:
              return date.format('L');
          }
        });
      },
      jamColor () {
        return getJamFactorTextColorFromValue(this.getChartDataAverageValue(this.history.jamFactor));
      },
      speedColor () {
        const averageSpeed = this.getChartDataAverageValue(this.history.averageSpeed);
        const averageFreeFlowSpeed = this.getChartDataAverageValue(this.history.freeFlowSpeed);
        return getSpeedColor(averageSpeed, averageFreeFlowSpeed);
      },
      averageJamType () {
        let total = 0;
        let average = 0;
        if (this.history.jamFactor !== null) {
          for (const jamFactor of this.history.jamFactor) {
            if (jamFactor !== null) {
              average += parseFloat(jamFactor);
              total++;
            }
          }
          average /= total;
        }

        return getJamFactorType(average);
      },
      jamTime () {
        let total = 0;
        if (this.history.jamFactor !== null) {
          for (const jamFactor of this.history.jamFactor) {
            if (jamFactor !== null && jamFactor >= 8) {
              total += this.step;
            }
          }
        }

        const hours = ((total - (total % 60)) / 60).toFixed(0);
        const minutes = (total % 60).toFixed(0);
        const arr = [];

        if (hours > 0) {
          arr.push(hours + 'h');
        }
        if (minutes > 0) {
          arr.push(minutes + 'min');
        }

        if (arr.length > 0) {
          return arr.join(' ');
        } else {
          return '0 min';
        }
      },
    },

    methods: {
      getJamFactorTextColorFromValue (value) {
        return getJamFactorTextColorFromValue(value);
      },

      getSpeedColor (history) {
        return getSpeedColor(this.getChartDataAverageValue(history), this.getChartDataAverageValue(this.history.freeFlowSpeed));
      },

      downloadChartAsCsv (type) {
        const headers = [];
        switch (type) {
          case 'speedEvolution':
            headers.push(
              this.$t('messages.views.admin.components.dashboard.sectionModal.window.history.csv.headers.timestamp'),
              this.$t('messages.views.admin.components.dashboard.sectionModal.window.history.csv.headers.averageSpeed')
            );
            break;
          case 'speedDistribution':
            headers.push(
              this.$t('messages.views.admin.components.dashboard.sectionModal.window.history.csv.headers.from'),
              this.$t('messages.views.admin.components.dashboard.sectionModal.window.history.csv.headers.to'),
              this.$t('messages.views.admin.components.dashboard.sectionModal.window.history.csv.headers.percentage')
            );
            break;
          case 'jamFactorEvolution':
            headers.push(
              this.$t('messages.views.admin.components.dashboard.sectionModal.window.history.csv.headers.timestamp'),
              this.$t('messages.views.admin.components.dashboard.sectionModal.window.history.csv.headers.jamFactor')
            );
            break;
          case 'jamFactorDistribution':
            headers.push(this.$t('messages.views.admin.components.dashboard.sectionModal.window.history.csv.headers.jamFactorType'));
            headers.push(this.$t('messages.views.admin.components.dashboard.sectionModal.window.history.csv.headers.percentage'));
            break;
        }

        const data = [];

        switch (type) {
          case 'speedEvolution':
            for (const i in this.chartLabels) {
              const values = [];
              values.push(this.history.averageSpeed[i]);
              data.push([
                Array.isArray(this.chartLabels[i]) ? this.chartLabels[i].join(' - ') : this.chartLabels[i],
                ...values,
              ]);
            }
            break;
          case 'speedDistribution':
            const slices = [];
            let sum = 0;
            for (const speed of this.history.averageSpeed) {
              if (speed !== null) {
                const index = Math.round(speed - (speed % this.speedDistributionSlices));
                if (slices[index] === undefined) {
                  slices[index] = 0;
                }
                slices[index]++;
                sum++;
              }
            }

            for (const index in slices) {
              data.push([
                index,
                (parseInt(index) + this.speedDistributionSlices - 1),
                (slices[index] / sum * 100).toFixed(2),
              ]);
            }
            break;
          case 'jamFactorEvolution':
            for (const i in this.chartLabels) {
              data.push([
                Array.isArray(this.chartLabels[i]) ? this.chartLabels[i].join(' - ') : this.chartLabels[i],
                (this.history.jamFactor[i] * 10).toFixed(0),
              ]);
            }
            break;
          case 'jamFactorDistribution':
            const values = [];
            let total = 0;
            for (const jamFactor of this.history.jamFactor) {
              if (jamFactor !== null) {
                const type = getJamFactorType(jamFactor);
                if (values[type] === undefined) {
                  values[type] = 0;
                }
                values[type]++;
                total++;
              }
            }

            for (const type in values) {
              data.push([
                this.$t('constants.section.jamFactor.' + type),
                Math.round(values[type] / total * 100),
              ]);
            }
            break;
        }

        downloadDataAsCsv('section_' + type, headers, data);
      },

      downloadChartAsPng (type) {
        const canvas = this.$refs[type + 'Chart'].$refs.canvas;
        downloadCanvasAsPng('section_' + type, canvas);
      },

      historyToChartData (timestamps, history) {
        const data = [];
        const historyTimestamps = history.map(item => parseInt(item.timestamp));
        for (const timestamp of timestamps) {
          const index = historyTimestamps.indexOf(timestamp);
          data.push(index !== -1 ? history[index].value : null);
        }

        return data;
      },

      getChartDataAverageValue (data) {
        if (!data) {
          return 0;
        }

        let average = 0;
        let total = 0;
        if (data.length) {
          data.forEach((value) => {
            if (value !== null) {
              average += parseFloat(value);
              total++;
            }
          });

          if (total) {
            average /= total;
          }
        }

        return Math.round(average);
      },

      onModeChange (newMode) {
        this.mode = newMode;
      },

      onDateChange (newDate) {
        this.current = newDate;
      },

      hasData (values) {
        return this.loading || (values && !!values.filter(value => value !== null).length);
      },
    },
  };
</script>

<style scoped lang="sass">
  .history-card
    height: 358px !important
</style>
